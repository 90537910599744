.rent {
  flex-direction: column;
  gap: 40px;
  margin-bottom: 80px;
  display: flex;
}

.rent__title {
  color: var(--basic-black);
  margin: 0;
  font-size: 52px;
  font-weight: 700;
}

.rent-grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  display: grid;
}

.rent-grid__item {
  flex-direction: column;
  gap: 22px;
  display: flex;
}

.rent-grid__item-img {
  border-radius: 40px;
  height: 400px;
  position: relative;
  overflow: clip;
}

.rent-grid__item-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.complex-grid__img-picture {
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: all .3s;
}

.complex-grid__img-picture:hover {
  transform: scale(1.3);
}

.rent-grid__item-text-title {
  color: var(--basic-black);
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: 700;
}

.rent-grid__item-text-price {
  color: #3d3d3d;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.rent-grid__item-text-margin {
  margin: 0;
  padding-top: 16px;
}

.complex__item-link:before {
  content: url("../../../images/main/icon.2365fbf5.svg");
  z-index: 1;
  cursor: pointer;
  pointer-events: none;
  backdrop-filter: blur(2px);
  background: #00000080;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: auto;
  transition: bottom .3s;
  display: flex;
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
}

.complex__item-link:hover:before {
  bottom: 25px;
}

@media (width <= 901px) {
  .rent-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .complex__item-link:before {
    display: none;
  }

  .complex-grid__img-picture:hover {
    transform: none;
  }
}

@media (width <= 601px) {
  .rent {
    gap: 32px;
    margin-bottom: 64px;
  }

  .rent__title {
    font-size: 42px;
  }

  .rent-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
  }

  .rent-grid__item {
    gap: 16px;
  }
}
/*# sourceMappingURL=index.9e9db95f.css.map */
