.rent {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 80px;
}

.rent__title {
    margin: 0;
    font-size: 52px;
    font-weight: 700;
    color: var(--basic-black);
}

.rent-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.rent-grid__item {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.rent-grid__item-img {
    position: relative;
    height: 400px;
    border-radius: 40px;
    overflow: clip;
}

.rent-grid__item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.complex-grid__img-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.3s;
}

.complex-grid__img-picture:hover {
    transform: scale(1.3);
}

.rent-grid__item-text-title {
    font-size: 22px;
    font-weight: 700;
    color: var(--basic-black);
    margin-bottom: 16px;
}

.rent-grid__item-text-price {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #3d3d3d;
}

.rent-grid__item-text-margin {
    margin: 0;
    padding-top: 16px;
}

.complex__item-link::before {
    content: url('../../../images/main/icon.svg');
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: -100px;
    margin: auto;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: none;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.5);
    transition: bottom 0.3s ease;
    backdrop-filter: blur(2px);
}

.complex__item-link:hover::before {
    bottom: 25px;
}

@media (max-width: 901px) {
    .rent-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .complex__item-link::before {
        display: none;
    }

    .complex-grid__img-picture:hover {
        transform: none;
    }
}

@media (max-width: 601px) {
    .rent {
        gap: 32px;
        margin-bottom: 64px;
    }

    .rent__title {
        font-size: 42px;
    }

    .rent-grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
    }

    .rent-grid__item {
        gap: 16px;
    }
}
